<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Acompanhe o limite utilizado dos usuários com convênio habilitado, gere relatórios de gastos para restaurar o valor utilizado. Clique sobre um afiliado para visualizar as compras realizadas.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-sm-6 col-md-5 col-lg-5 mb-3">
							<label><i class="far fa-store color-theme font-11 me-1"></i> Loja</label>
							<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="pesquisa.loja" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Todas as lojas">
								<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
								<template v-slot:no-options>
									<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
								</template>
							</v-select>
							<select name="Mobile" class="form-control" v-model="pesquisa.loja">
								<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
							</select>
                  </div>
                  <div class="col-9 col-sm-6 col-md-5 col-lg-6 mb-3">
							<label><i class="far fa-user color-theme font-11 me-1"></i> Nome/CPF/RG</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarAfiliados" placeholder="Busque por nome do afiliado, CPF ou RG" />
                  </div>
						<div class="col-3 col-md-2 col-lg-1 px-0 mb-3 align-self-end">
							<button type="button" class="btn btn-primary w-100" @click="buscarAfiliados"><i class="fas fa-search font-13"></i></button>
						</div>
					</div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col-sm mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2" v-if="pesquisa.retorno.idLoja && dadosUsuario.usuarioPermissoes.includes('Convênios | Gerar relatórios')">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="gerarRelatorio"><i class="far fa-file-search font-10 me-1"></i> Gerar relatório</a>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>

						<afiliado v-for="(afiliado, index) in pesquisa.retorno.resultado" :key="index" :afiliado="afiliado" :index="index" :resumido="false" 
							@visualizar="buscarComprovantes($event, false)" />
					</div>
				</div>
			</div>
      </div>

      <!-- modalComprovantes -->
      <div class="modal fade" id="modalComprovantes" tabindex="-1" aria-labelledby="modalComprovantesLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalComprovantesLabel">Comprovantes - Afiliado {{ modal.nomeCompleto == null ? '' : ('('+ modal.nomeCompleto +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body" v-if="modal.resultado.length > 0">
                  <div class="cards-150 cards-sm-200">
                     <comprovante v-for="(cupom, index) in modal.resultado" :key="index" :index="index" :cupom="cupom" :preview="true" @visualizar="verComprovante($event)" />
                  </div>

                  <div class="w-100 mt-3 mb-2 text-center" v-if="modal.resultado.length % 30 == 0">
                     <button type="button" class="btn btn-secondary btn-md font-12" @click="buscarComprovantes(null, false)">Ver mais</button>
                  </div>
               </div>
               <div class="modal-body my-5 text-center" v-else>
						<i class="fal fa-print-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
						<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum comprovante encontrado</h4>
					</div>
               <div class="modal-footer">
						<button type="button" class="btn btn-primary" v-if="modal.resultado.length > 0" @click="buscarComprovantes(null, true)">Exportar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

		<modalComprovante :modalAnterior="'#modalComprovantes'" :tipo="comprovante.tipo" :comprovante="comprovante.dados" />
   </div>
</template>

<script>

import $ from 'jquery'
import router from '@/router'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import comprovante from '@/components/resultados/Comprovante.vue'
import afiliado from '@/components/convenios/afiliados/Afiliado.vue'
import modalComprovante from '@/components/resultados/ModalComprovante.vue'

export default {
	name: 'Afiliados',
	data: function () {
		return {
			pesquisa: {'loja': null, 'convenioHabilitado': true, 'nome': '', 'retorno': {'idLoja': null, 'numeroLoja': null, 'nome': null, 'resultado': []}},
			modal: {'idUsuario': null, 'nomeCompleto': null, 'resultado': [], 'pagina': 0},
			comprovante: {'tipo': null, 'dados': null},
			listaExportacao: []
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      }
	},
	components: {
		afiliado, comprovante, modalComprovante
	},
	methods: {
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Afiliados',
				'resultado': this.pesquisa.retorno.resultado, 
				'campos': ['id', 'nomeFantasia', 'email', 'cpfCnpj', 'convenioHabilitado', 'valor'], 
				'lista': null
			})
      },
		verComprovante : function (comprovante) {
			this.comprovante = JSON.parse(JSON.stringify(comprovante))
			$("#modalComprovantes").modal('hide')
			$("#modalVerComprovante").modal('show')
		},
		buscarAfiliados : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

			this.$axios({
				method: 'post',
				url: this.urlRest +'convenios/searchUsuarios',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarComprovantes : function (objAfiliado, isExcel) {
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.modal = {
				'idUsuario': objAfiliado != null ? objAfiliado.id : this.modal.idUsuario, 
				'nomeCompleto': objAfiliado != null ? objAfiliado.nomeCompleto : this.modal.nomeCompleto, 
				'pagina': isExcel ? -1 : (objAfiliado != null ? 0 : this.modal.pagina), 
				'resultado': objAfiliado != null ? [] : this.modal.resultado
			}

			$('#modalComprovantes').modal('show')
			Object.assign(this.$data.listaExportacao, this.$options.data.apply(this).listaExportacao)

			this.$axios({
				method: 'post',
				url: this.urlRest +'convenios/searchComprovantes',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

			}).then(response => {
				if (isExcel) {
               this.listaExportacao = response.data
            } else {
               this.modal.pagina++;
               this.modal.resultado.push(... response.data)
            }
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)

				if (isExcel) {
					this.$store.dispatch('exportarExcel', {
						'nome': 'Comprovantes',
						'resultado': this.listaExportacao, 
						'campos': ['id', 'idPdv', 'formaPagto', 'data', 'valor'], 
						'lista': null
					})
            }
			});
		},
		gerarRelatorio : function () {
			Swal.fire({
            icon: 'warning',
            title: 'Gerar relatórios dos gastos?',
            text: 'Isso irá restaurar o valor utilizado de todos os afiliados a loja.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'convenios/gerarRelatorioLoja',
                  params: {
                     idLoja: this.pesquisa.retorno.idLoja
                  }
               }).then((response) => {
						this.buscarAfiliados()

                  Swal.fire({
                     icon: 'success',
                     title: 'Relatório gerado!',
                     text: 'Valor total: R$'+ parseFloat(response.data).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
                     showCancelButton: true,
                     confirmButtonText: 'Ir para relatórios',
                     cancelButtonText: `Fechar`
                     
                  }).then((result) => {
                     if (result.isConfirmed) {
                        router.push('/relatorios/'+ this.tabs.length)
                     }
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
		}
	},
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>